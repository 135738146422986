const ipfsClient = require('ipfs-api');

const projectId = '24IwuMmPbbYURK5ovuNpdIErXHA'
const projectSecret = 'ddff8213b5a7e5155c5dc9baa1ebae9f'
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

const ipfs = new ipfsClient({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: auth
  }
});

export const getImageIpfsHash = async (data) => {
    const result = await ipfs.files.add(data);
    const hash = await result[0].hash;
    return hash;
};
