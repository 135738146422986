import React, { useState, useEffect } from "react";
import axios from 'axios'
import * as style from './styles';
import ItemCard from "../Cards/ItemCard";
import FilterIcon from '../../assets/images/filter.png';
import SearchIcon from '../../assets/images/search-icon.png';

import "./ExploreItemList.css";

const SELECT_FILE_TYPES = [
  {value: 'image', text: 'Image'},
  {value: 'video', text: 'Video'},
  {value: 'audio', text: 'Audio'},
];

const SELECT_SALE_TYPES = [
  {value: 'auction', text: 'Timed auction'},
  {value: 'fixed', text: 'Fixed Price'},
  {value: 'not_sale', text: 'Not for sale'}
];

const SELECT_ORDER_BY_ITEMS = [
  {value: 'timestamp', text: 'Recently added'},
  {value: 'usdPrice', text: 'Price: Low to High'},
  {value: 'usdPrice', text: 'Price: High to Low'},
  {value: 'auctionEndTimestamp', text: 'Auction ending soon'},
];

const ExploreItemList = (props) => {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [noItems, setNoItems] = useState(false)
  const [initialItemsLoaded, setInitialItemsLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  const [categories, setCategories] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);

  const [filters, setFilters] = useState({
    category: null,
    assetType: null,
    saleType: null, 
  });
  const [selectedFilters, setSelectedFilters] = useState({
    category: null,
    assetType: null,
    saleType: null, 
  });

  const [searchTxt, setSearchTxt] = useState("")   
  const [tempSearchTxt, setTempSearchTxt] = useState("") 
  const [sortBy, setSortBy] = useState("timestamp")
  const [sortByText, setSortByText] = useState("Recently added")  
  const [sortDir, setSortDir] = useState("desc")

  function chooseFilter(filter) {
    setSortBy(filter.value); 
    setSortByText(filter.text); 
    setShowSortBy(false);

    switch (filter.text) {
      case 'Recently added':
        setSortDir('desc')
        break;
      case 'Price: Low to High':
        setSortDir('asc')
        break;
      case 'Price: High to Low':
        setSortDir('desc')
        break;
      case 'Auction ending soon':
        setSortDir('asc')
        setFilters({ category: selectedFilters.category, assetType: selectedFilters.assetType, saleType:{value: 'auction', text: 'Timed auction'} })
        setSelectedFilters({ category: selectedFilters.category, assetType: selectedFilters.assetType, saleType:{value: 'auction', text: 'Timed auction'} })
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (categories.length === 0) fetchCategories();
  }, [categories]);
  function fetchCategories() {        
    axios.get(`/api/categories`)
    .then((res) => {            
        setCategories(res.data.categories);                       
    })
    .catch((err) => {
        console.log("err: ", err.message);
        setCategories([]);
    });
  }


  function onSetCategory(category) {
    if (filters.category && (filters.category?.name === category.name)) {
        setFilters({ category: null, assetType: filters.assetType, saleType:filters.saleType })
    } else {
        setFilters({ category: category, assetType: filters.assetType, saleType:filters.saleType })
    }        
  } 
  function onSetAssetType(assetType) {
    if (filters.assetType && (filters.assetType?.value === assetType.value)) {
        setFilters({ category: filters.category, assetType: null, saleType:filters.saleType })
    } else {
        setFilters({ category: filters.category, assetType: assetType, saleType:filters.saleType })
    }        
  }
  function onSetSaleType(saleType) {
    if (filters.saleType && (filters.saleType?.value === saleType.value)) {
        setFilters({ category: filters.category, assetType: filters.assetType, saleType:null })
    } else {
        setFilters({ category: filters.category, assetType: filters.assetType, saleType:saleType })
    }  
  }

  function removeCategory() {
    setFilters({ category: null, assetType: selectedFilters.assetType, saleType:selectedFilters.saleType })
    setSelectedFilters({ category: null, assetType: selectedFilters.assetType, saleType:selectedFilters.saleType })        
  } 
  function removeAssetType() {
    setFilters({ category: selectedFilters.category, assetType: null, saleType:selectedFilters.saleType })
    setSelectedFilters({ category: selectedFilters.category, assetType: null, saleType:selectedFilters.saleType })        
  }
  function removeSaleType() {
    setFilters({ category: selectedFilters.category, assetType: selectedFilters.assetType, saleType:null })
    setSelectedFilters({ category: selectedFilters.category, assetType: selectedFilters.assetType, saleType:null })        
  } 

  function onSetSelectedFilters() {
    setShowFilter(false)
    setSelectedFilters(filters)        
  }
  function onClearAll() {
    setFilters({category: null, assetType: null, saleType: null})
    setSelectedFilters({category: null, assetType: null, saleType: null})
  }


  useEffect(() => {    
    setItems([])
    setNoItems(false)
    setInitialItemsLoaded(false)
    setLoading(true)   
    setPage(1)    
    fetchItems(true)    
  }, [props, selectedFilters, searchTxt, sortBy, sortDir])

  useEffect(() => {
    setLoading(true)    
    if (initialItemsLoaded){
      fetchItems(false);
    }
  }, [page])

  function fetchItems(reset){    
    let queryUrl = `${props.query}&page=${reset ? 1 : page}&sortDir=${sortDir}${sortBy ? '&sortBy=' + sortBy : ''}${selectedFilters.saleType ? '&saleType=' + selectedFilters.saleType.value : ''}${selectedFilters.assetType ? '&asset_type=' + selectedFilters.assetType.value : ''}${selectedFilters.category ? '&category=' + selectedFilters.category.name : ''}${searchTxt ? '&searchTxt=' + searchTxt : ''}`
        
    axios.get(queryUrl)
    .then(res => {
      setLoading(false)   
      if (res.data.items.length === 0) setNoItems(true)      
      if (reset){        
        setItems(res.data.items)
        setInitialItemsLoaded(true)
      }else{
        let prevArray = JSON.parse(JSON.stringify(items))
        prevArray.push(...res.data.items)
        setItems(prevArray)        
      }
      
    })
    .catch(err => {            
      setLoading(false)  
      if (err.response.data.message === 'No Items found') {
        setNoItems(true)    
      }      
    })
  }

  function loadMore() {
    if (!loading) {
      setPage(page => {return (page + 1)}) 
    }      
  }

  return (
    <div className="item-list">
      {
        props.place === "collection" ? 
        <></>
        :
        <h1>Explore</h1>
      }
      
      <style.Container>
        <div className="filterBox" onClick={() => {setSearchTxt(tempSearchTxt); }}>
          <div className="item-box" onClick={() => setShowSortBy(false)}>
            <input type="text" value={tempSearchTxt} className="form-search" style={{backgroundImage: `url(${SearchIcon})`}} placeholder="Search Items" onChange={event => {setTempSearchTxt(event.target.value)}} onKeyDown={event => {
              if (event.key === 'Enter')
                setSearchTxt(event.target.value)
            }}/>
          </div>
          <div className="item-box" onClick={() => setShowSortBy(false)}>
            <button className="cta-button filter-button" onClick={() => setShowFilter(!showFilter)}><img src={FilterIcon} alt="FilterIcon" /> Filters</button>
          </div>
          <div className="item-box">
            <div className="selectWrap">
              <button className="cta-button filter-button" onClick={() => setShowSortBy(!showSortBy)}> {sortByText}</button>
              <style.DropDownMenus style={{display : showSortBy ? '' : 'none'}}>
              {
                SELECT_ORDER_BY_ITEMS.map(o => <style.DropDownMenu
                    onClick={() => { chooseFilter(o)}}>
                    {o.text}
                  </style.DropDownMenu> )
              }                                          
              </style.DropDownMenus>
            </div>
          </div>
          {
            showFilter &&
            <style.FilterContent>
                <style.FilterCategoryContainer>
                    <style.FilterLabel>Categories: </style.FilterLabel>
                    <style.FilterCategories>
                        {
                            categories.map((category, index) => <style.FilterCategory key={index} onClick={() => onSetCategory(category)} className={filters.category?.name === category.name?'active':''}>{category.name}</style.FilterCategory>)
                        }
                    </style.FilterCategories>
                </style.FilterCategoryContainer>
                <style.FilterCategoryContainer>
                    <style.FilterLabel>File Types: </style.FilterLabel>
                    <style.FilterCategories>
                        {
                            SELECT_FILE_TYPES.map((assetType, index) => <style.FilterCategory key={index} onClick={() => onSetAssetType(assetType)} className={filters.assetType?.value === assetType.value?'active':''}>{assetType.text}</style.FilterCategory>)
                        }
                    </style.FilterCategories>
                </style.FilterCategoryContainer>                            
                <style.FilterFooter>
                    <style.FilterSaleTypesContainer>
                        <style.FilterLabel>Sale Types: </style.FilterLabel>
                        <style.FilterSaleTypes>
                        {
                            SELECT_SALE_TYPES.map((saleType, index) => <style.FilterCategory key={index} onClick={() => onSetSaleType(saleType)} className={filters.saleType?.value === saleType.value?'active':''}>{saleType.text}</style.FilterCategory>)
                        }                                            
                        </style.FilterSaleTypes>                                        
                    </style.FilterSaleTypesContainer>
                    <button className="cta-button" onClick={() => onSetSelectedFilters()}>Ok</button>
                </style.FilterFooter>                            
            </style.FilterContent>
          }
        </div>
        {
          (selectedFilters.category || selectedFilters.assetType || selectedFilters.saleType) &&
          <style.FilterString>
              {
                  selectedFilters.category &&
                      <style.FilterStringItem>
                          <label>Category: </label>
                          <style.FilterValue>{selectedFilters.category.name}<style.RemoveIcon size={12} onClick={() => removeCategory()}/></style.FilterValue>
                      </style.FilterStringItem>
              }
              {
                  selectedFilters.assetType &&
                  <style.FilterStringItem>
                      <label>File Type: </label>
                      <style.FilterValue>{selectedFilters.assetType.text}<style.RemoveIcon size={12} onClick={() => removeAssetType()}/></style.FilterValue>
                  </style.FilterStringItem>
              }
              {
                  selectedFilters.saleType &&
                  <style.FilterStringItem>
                      <label>Sale Type: </label>
                      <style.FilterValue>{selectedFilters.saleType.text}<style.RemoveIcon size={12} onClick={() => removeSaleType()}/></style.FilterValue>
                  </style.FilterStringItem>
              }                        
              <style.FilterClearAll onClick={() => onClearAll()} >
                  clear all
              </style.FilterClearAll>
          </style.FilterString>
        }  
        <div className="all-items">
          {items.map((item, index) => (
            <ItemCard {...props} item={item} key={index} />
          ))}
        </div>
        <div className="load-more" style={{display: noItems ? "none" : ""}}>
            <button onClick={() => loadMore()} className="" type="primary" >
              {loading ? "Loading..." : "Load more"}
            </button>
        </div>        
      </style.Container>


      
    </div>
    
  );
};

export default ExploreItemList;
