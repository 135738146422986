import { Switch } from "antd";
import React, {useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from '@web3-react/core'

import { connectorLocalStorageKey, injectedConnector } from "../../utils/connectors"
import { getTokenBalance } from "../../utils/contracts";
import { formatNum, getUSDRateFromSymbol } from "../../utils";
import { logout, useAuthDispatch } from "../../context/authContext";

import ThemeFun from "../../functions/ThemeFun";
import "./ProfileDrop.css";

import BNBIcon from "../../assets/png/bnb.png";
import SAUNAIcon from "../../assets/png/sauna.png";


const ProfileDrop = () => {
  const {account, active, chainId, deactivate,library} = useWeb3React();
  const dispatch = useAuthDispatch();
  const { darkTheme, toggleDarkTheme } = ThemeFun();
  const [bnbBalance, setBnbBalance] = useState(0)
  const [bnbRate, setBnbRate] = useState(0)
  const [saunaBalance, setSaunaBalance] = useState(0)  
  const [saunaRate, setSaunaRate] = useState(0)
  

  const signOut = async() => {
    deactivate(injectedConnector)     
    window.localStorage.setItem(connectorLocalStorageKey, "");
    logout(dispatch)
  }
  
  useEffect(() => {
    if(!!account && !!library) {
      // get BNB balance and rate      
      getTokenBalance(account, 'BNB', chainId, library)
        .then((balance) => {
          setBnbBalance(Number(balance))
        })
        .catch(() => {
          setBnbBalance(0)
        })  
      getUSDRateFromSymbol('BNB')
        .then((rate) => {          
          setBnbRate(rate)
        })
        .catch(() => {
          setBnbRate(0)
        })

      // get SAUNA balance
      getTokenBalance(account, 'SAUNA', chainId, library)
        .then((balance) => {
          setSaunaBalance(Number(balance))
        })
        .catch(() => {
          setSaunaBalance(0)
        })
      getUSDRateFromSymbol('SAUNA')
        .then((rate) => {
          setSaunaRate(rate)
        })
        .catch(() => {
          setSaunaRate(0)
        })    
    }

    return () => {
      setBnbBalance(0)
      setSaunaBalance(0)
      setBnbRate(0)
      setSaunaRate(0)
    }
  }, [account, chainId, library])

  const changeSwitch = (e) => {
    toggleDarkTheme(e)
    if (e) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  };
  
  return (
    <div className="profile-drop">
      
      <div className="profile-drop-account">
        <div className="profile-drop-header">
          <img
            src={BNBIcon}
            alt="BNB"
          />
          <div className="profile-drop-header-context">
            <p>BNB Balance</p>
            <p>
              <span>{formatNum(bnbBalance)} BNB</span> ≈ ${formatNum(bnbBalance * bnbRate)}
            </p>
          </div>
        </div>
        <div className="profile-drop-header">
          <img
              src={SAUNAIcon}
              alt="SAUNA"
            />
          <div className="profile-drop-header-context">
            <p>SAUNA Balance</p>
            <p>
              <span>{formatNum(saunaBalance)} SAUNA</span> ≈ ${formatNum(saunaBalance * saunaRate)}
            </p>
          </div>
        </div>
      </div>
      <div className="profile-drop-manage">        
        <a href={"https://pancakeswap.finance/swap?outputCurrency=0x06b889a7a7fa15d8cc7cff147962c4232cce7cf0"} target="_blank" rel="noreferrer">Manage Funds</a>
      </div>
      <div className="profile-drop-menu">
        <div className="profile-drop-menu-item">
          <Link to={`/user/${account}`}>
          <h3>My Profile</h3>
          </Link>
      </div>
      <div className="profile-drop-menu-item">
        <Link to="/edit">
          <h3>Edit profile</h3>
        </Link>
      </div>
      <div className="profile-drop-menu-item" style={{display:'none'}}>
        <h3>Dark theme</h3>
        <Switch checked={darkTheme} onChange={changeSwitch} size="small" />
      </div>
      <div className="profile-drop-menu-item" onClick={() => signOut()}>
        <h3>Disconnect</h3>
      </div>
    </div>
    </div >
  );
};

export default ProfileDrop;
