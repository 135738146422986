import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as header from "./style";
import * as footer from "./style";

// community images
import TwitterIcon from "../../assets/images/icon-twitter.png";
import TelegramIcon from "../../assets/images/icon-telegram.png";
import DiscordIcon from "../../assets/images/icon-discord.png";
import MediumIcon from "../../assets/images/icon-medium.png";
import DocsIcon from "../../assets/images/icon-docs.png";


import PaperPlane from "../../assets/images/paper-plane.svg";

export class Footer extends Component {
    render() {
        return (
            <footer.FiteFooter>
                <header.Container>

                    <div class="top-Box">
                        <div class="subscrib-Box">
                            <div className="inner-box">
                                <h6 style={{marginBottom:'10px'}}>Subscribe to our newsletter</h6>
                                
                                <form>
                                    <div className="form-field">
                                        <div className="inputGroup">
                                            <input type="text" className="form-control" placeholder="Your Email" />
                                            <div value="" className="form-button" style={{backgroundImage: `url(${PaperPlane})`}}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="community-Box">
                            <h5>Community</h5>
                            <ul>
                                <li><a href={"https://twitter.com/sauna_finance"} target="_blank" rel="noreferrer"><img src={TwitterIcon} alt="Twitter" /></a></li>
                                <li><a href={"https://discord.com/invite/qKCfj3SXSV"} target="_blank" rel="noreferrer"><img src={DiscordIcon} alt="Discord" /></a></li>
                                <li><a href={"https://t.me/Saunafinance"} target="_blank" rel="noreferrer"><img src={TelegramIcon} alt="Telegram" /></a></li>
                                <li><a href={"https://sauna-finance.medium.com/"} target="_blank" rel="noreferrer"><img src={MediumIcon} alt="Medium" /></a></li>
                                <li><a href={"https://docs.sauna.finance/"} target="_blank" rel="noreferrer"><img src={DocsIcon} alt="Docs" /></a></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="bottom-Box">
                        <div class="logoInfo-Box">                            
                            <p>Please contact us if you have any specific idea or request <Link to="/">info@sauna.com</Link> </p>
                        </div>
                        <div class="menulinks-Box">
                            <div class="menulinks-1">
                                <h5>Marketplace</h5>
                                <ul>
                                    <li><Link to="/">Explore </Link></li>                                    
                                </ul>
                            </div>
                            <div class="menulinks-2">
                                <h5>Service</h5>
                                <ul>
                                    <li>
                                        <a href={"https://pancakeswap.finance/swap?outputCurrency=0x06b889a7a7fa15d8cc7cff147962c4232cce7cf0"} target="_blank" rel="noreferrer">Buy Crypto</a>
                                    </li>                                    
                                </ul>
                            </div>
                            <div class="menulinks-3">
                                <h5>Support</h5>
                                <ul>
                                    <li><Link to="/how-it-work">How It Work </Link></li>                                    
                                </ul>
                            </div>
                        </div>
                    </div>                    
                </header.Container>
            </footer.FiteFooter>
        );
    }
}


