import React, { useEffect, useState } from "react";
import "./SellerItem.css";
import Badge from "../../assets/svg/verified_badge.svg";
import SellerLoader from "../ContentLoaders/SellerLoader";
import { useHistory } from "react-router";

const SellerItem = (props) => {
  const { userItem ,count } = props;

  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const goToProfilePage = () => {
    history.push(`/user/${userItem?.userInfo.address}`)
  }

  return (
    <div className="seller-item" onClick={goToProfilePage}>
      {loading ? (
        <SellerLoader />
      ) : (
        <div className="seller-item-container">
          <p className="seller-item-number">{count}</p>
          <div className="seller-item-content">
            <h3>{userItem?.userInfo.name}</h3>
            <p>$ {userItem?.totalSold}</p>
          </div>
          <div className="seller-item-image">
            <img
              className="seller-avatar"
              src={userItem?.userInfo.profilePic}
              alt="profile logo"
            />
            <img className="seller-badge" src={Badge} alt="verified badge" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SellerItem;
