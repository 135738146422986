import { useState } from "react";
import { getImageIpfsHash } from "../utils/ipfs";

const CreateFun = () => {
  const [mainFile, setMainFile] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [collectionImage, setCollectionImage] = useState("");

  const [collectionImgUploading, setCollectionImgUploading] = useState(false)
  const [mainFileUploading, setMainFileUploading] = useState(false)
  const [coverImgUploading, setCoverImgUploading] = useState(false)

  const pickMainFile = (e) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type.split("/")[0]
      if (fileType === "image") {
        let reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0])
        setMainFileUploading(true)
        reader.onload = (e) => {
          const buffer = Buffer.from(reader.result);
          getImageIpfsHash(buffer).then((hash) => {
            setMainFile(`https://ipfs.io/ipfs/${hash}`)
            setCoverImage(`https://ipfs.io/ipfs/${hash}`)
            setMainFileUploading(false) 
            setCoverImgUploading(false)   
            setMediaType(fileType)                    
          })
        };
      }else if ((fileType === "video") || (fileType === "audio")) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0])
        setMainFileUploading(true)
        reader.onload = (e) => {
          const buffer = Buffer.from(reader.result);
          getImageIpfsHash(buffer).then((hash) => {
            setMainFile(`https://ipfs.io/ipfs/${hash}`)
            setCoverImage("")
            setMainFileUploading(false) 
            setCoverImgUploading(false)  
            setMediaType(fileType)                     
          })
        };
      }
      
    }
  };

  const pickCoverImage = (e) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type.split("/")[0]
      if (fileType === "image") {        
        let reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0])
        setCoverImgUploading(true)
        reader.onload = (e) => {
          const buffer = Buffer.from(reader.result);
          getImageIpfsHash(buffer).then((hash) => {
            setCoverImage(`https://ipfs.io/ipfs/${hash}`)
            setCoverImgUploading(false)               
          })
        };                
      }
    }
  };


  const pickCollection = (e) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type.split("/")[0]
      if (fileType === "image") {
        let reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0])
        setCollectionImgUploading(true)
        reader.onload = (e) => {
          const buffer = Buffer.from(reader.result);
          getImageIpfsHash(buffer).then((hash) => {
            setCollectionImage(`https://ipfs.io/ipfs/${hash}`)
            setCollectionImgUploading(false)               
          })
        };
        
      }
    }
  };

  const removeMainFile = () => {
    setMainFile("")
    setMediaType("")
    setCoverImage("")
    setMainFileUploading(false)
    setCoverImgUploading(false)
  }

  const removeCoverImage = () => {
    setCoverImage("")
    setCoverImgUploading(false)
  }
  const removeCollectionImage = () => {
    setCollectionImage("")
    setCollectionImgUploading(false)
  }

  return { pickMainFile, mainFile, mediaType, removeMainFile, mainFileUploading,
          pickCoverImage, coverImage, removeCoverImage, coverImgUploading,
          pickCollection, collectionImage, removeCollectionImage, collectionImgUploading };
};

export default CreateFun;
